<template>
  <div ref="newModal" class="audit-modal-wrap">
    <a-modal
      :getContainer="() => $refs.newModal"
      title="驳回详情"
      visible
      :width="1200"
      @cancel="onCancel"
      :maskClosable="false"
      :keyboard="false"
      :footer="null"
    >
      <section class="layout-modal">
        <section class="audit-wrap">
          <div class="process-wrap" ref="process">
            <a-timeline>
              <a-timeline-item
                :color="colorFilter(item.operationAction)"
                v-for="(item, index) in operationLogs"
                :key="index"
              >
                <template #dot>
                  <CheckCircleOutlined
                    v-if="item.operationAction == 'submit' || item.operationAction == 're_self_evaluate_after_reject'"
                    style="font-size: 20px; color: #1bc643"
                  />
                  <ClockCircleOutlined
                    v-if="item.operationAction == 're_self_evaluate_ing'"
                    style="font-size: 20px; color: #ffe58f"
                  />
                  <CloseCircleOutlined
                    v-if="item.operationAction == 'reject'"
                    style="font-size: 20px; color: #ee3f3f"
                  />
                  <QuestionCircleOutlined
                    v-if="item.operationAction == 'pass' && !item.operationTime"
                    style="font-size: 20px; color: #999"
                  />
                  <CheckCircleOutlined
                    v-if="item.operationAction == 'pass' && item.operationTime"
                    style="font-size: 20px; color: #1bc643"
                  />
                </template>
                <div
                  class="process-list"
                  :class="processIndex == index ? 'process-list-active' : ''"
                  @click="changeProcessIndex(index)"
                >
                  <template v-if="item.operationAction == 'submit'">
                    <div class="title title-green">自评提交</div>
                    <div class="people">提交人：{{ item.operatorUser }}</div>
                    <div class="time">提交时间：{{ item.operationTime }}</div>
                  </template>

                  <template v-if="item.operationAction == 'reject'">
                    <div class="title title-red">县（市、区）驳回</div>
                    <div class="people">驳回人：{{ item.operatorUser }}</div>
                    <div class="time">驳回时间：{{ item.operationTime }}</div>
                  </template>

                  <template v-if="item.operationAction == 're_self_evaluate_after_reject'">
                    <div class="title">驳回已修改</div>
                    <div class="people">修改人：{{ item.operatorUser }}</div>
                    <div class="time">修改时间：{{ item.operationTime }}</div>
                  </template>

                  <template v-if="item.operationAction == 're_self_evaluate_ing'">
                    <div class="title">驳回修改中</div>
                    <template v-if="item.operatorUser">
                      <div class="people">{{ item.operatorUser }}协作修改</div>
                      <div class="time" v-if="item.operationTime">协作时间：{{ item.operationTime }}</div>
                    </template>
                  </template>

                  <template v-if="item.operationAction == 'pass'">
                    <template v-if="item.operationTime">
                      <div class="title title-green">县（市、区）已核准</div>
                      <div class="people">核准人：{{ item.operatorUser }}</div>
                      <div class="time">核准时间：{{ item.operationTime }}</div>
                    </template>
                    <template v-else>
                      <div class="title">待进行 县（市、区）核准</div>
                    </template>
                  </template>
                </div>
              </a-timeline-item>
            </a-timeline>
          </div>
          <section class="audit-shu"></section>
          <section class="content-wrap">
            <template v-if="operationLog.operationDesc">
              <div class="title">驳回意见</div>
              <div class="value" v-html="operationLog.operationDesc"></div>
            </template>
          </section>
        </section>
      </section>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import {
  PlusOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons-vue';
export default {
  name: '',
  components: { PlusOutlined, ClockCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined },
  emits: ['onCancel'],
  props: {
    operationLogs: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      processIndex: 0
    };
  },
  computed: {
    ...mapGetters([]),
    operationLog() {
      return this.operationLogs[this.processIndex] || {};
    }
  },
  mounted() {
    const _this = this;
    _this.initProcessIndex();
    this.$nextTick(() => {
      this.$refs.process.scrollTop = this.$refs.process.scrollHeight;
    });
  },
  methods: {
    initProcessIndex() {
      const _this = this;
      for (let i = 0; i < _this.operationLogs.length; i++) {
        if (!_this.operationLogs[i].operationTime) {
          _this.processIndex = i - 1;
          break;
        }
      }
    },
    changeProcessIndex(index) {
      if (this.processIndex == index) {
        return false;
      }
      this.processIndex = index;
    },
    colorFilter(value) {
      let colorText = ''; //
      switch (value) {
        case 'reject':
          colorText = '#ee3f3f';
          break;
        case 're_self_evaluate_ing':
          colorText = '#1BC643';
          break;
        case 'submit':
          colorText = '#1BC643';
          break;
        case 're_self_evaluate_after_reject':
          colorText = '#999999';
          break;
        default:
          colorText = '#1BC643';
      }
      return colorText;
    },
    /**
     * 点击取消
     */
    onCancel() {
      try {
        const _this = this;
        _this.$emit('onCancel');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang="less">
.audit-modal-wrap {
  .ant-modal-body {
    padding: 0;
  }
}
</style>
<style lang="less" scoped>
.layout-modal {
  height: 70vh;
  overflow-y: auto;
  .remark {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }

  .audit-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    position: relative;
    .process-wrap {
      flex: 3;
      padding: 20px 32px;
      position: relative;
      overflow-y: auto;
      cursor: pointer;
      .process-list-active {
        background: #e5eeff;
        border-radius: 8px;
      }
      .process-list {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
        padding: 0 8px;

        .title {
          font-size: 16px;
          color: #999999;
          line-height: 25px;
          padding-top: 1px;
        }
        .title-green {
          color: #1bc643;
        }
        .title-red {
          color: #ee3f3f;
        }
        .people {
          font-size: 14px;
          color: #333333;
          line-height: 26px;
        }
      }
    }
    .audit-shu {
      flex: 0 0 1px;
      background: #c5c5c5;
      height: 100%;
    }
    .content-wrap {
      flex: 7;
      padding: 12px;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .value {
        font-size: 16px;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}
</style>

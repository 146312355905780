<template>
  <a-spin :spinning="spinning" :tip="tipText" size="large">
    <section class="assessment-scroll">
      <section class="assessment-wrap">
        <section class="content-wrap">
          <section class="left-wrap">
            <section class="scroll-wrap">
              <template v-if="pageType == 'edit' || pageType == 'revise'">
                <div class="synergy-head">
                  <div class="title">领域指标</div>
                  <template v-if="!canChangeCommunityOperateType">
                    <template v-if="!isSynergy">
                      <template v-if="pageType == 'edit'">
                        <a-button type="primary" :disabled="true"> 在线协作 </a-button>
                      </template>
                      <template v-if="pageType == 'revise'">
                        <a-button type="text" size="large" disabled>非协作模式</a-button>
                      </template>
                    </template>
                    <template v-else>
                      <a-button type="text" size="large" disabled>协作模式</a-button>
                    </template>
                  </template>
                  <template v-else>
                    <a-popconfirm
                      placement="top"
                      ok-text="确定"
                      cancel-text="取消"
                      @confirm="handleChangeCommunityOperateType('synergy')"
                      v-if="!communityOperateType"
                    >
                      <template #title> 确定开启在线协作模式？ </template>
                      <a-button type="primary"> 在线协作 </a-button>
                    </a-popconfirm>
                    <a-popconfirm
                      placement="top"
                      ok-text="确定"
                      cancel-text="取消"
                      v-else
                      @confirm="handleChangeCommunityOperateType('single')"
                    >
                      <template #title> 确定取消在线协作模式？ </template>
                      <a-button size="large" danger>取消协作</a-button>
                    </a-popconfirm>
                  </template>
                </div>
              </template>

              <a-collapse v-model:activeKey="collapseActiveKey" :bordered="false">
                <template #expandIcon="{ isActive }">
                  <caret-right-outlined :style="collapseArrow" :rotate="isActive ? 90 : 0" />
                </template>
                <a-collapse-panel v-for="(realm, realmIndex) in realmsList" :key="realmIndex">
                  <template #header>
                    <template v-if="pageType == 'edit'">
                      <section class="collapse-header">
                        {{ toChinesNum(realmIndex + 1) }}、{{ realm.name }}({{ realm.score }}分)
                      </section>
                    </template>
                    <template v-if="pageType == 'look'">
                      <section class="collapse-header">
                        {{ toChinesNum(realmIndex + 1) }}、{{ realm.name }}({{ realm.score }}分)
                      </section>
                    </template>
                    <template v-if="pageType == 'revise'">
                      <section
                        v-if="!isEmpty(realmResults)"
                        class="collapse-header"
                        :class="
                          Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) != 3
                            ? 'collapse-disabled'
                            : ''
                        "
                      >
                        {{ toChinesNum(realmIndex + 1) }}、{{ realm.name }}({{ realm.score }}分)
                      </section>
                    </template>
                  </template>

                  <div
                    class="metric-wrap"
                    :class="activeMetricIdToKeyBySelf == metric.metricIdToKeyBySelf ? 'metric-wrap-active' : ''"
                    v-for="(metric, metricIndex) in realm.metrics"
                    :key="metricIndex"
                    @click="changeMetric(metric)"
                  >
                    <template v-if="pageType == 'revise'">
                      <span
                        :class="
                          !isEmpty(realmResults) &&
                          Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) != 3
                            ? 'collapse-disabled'
                            : ''
                        "
                      >
                        <span class="metric-name"> {{ metric.showIndex }}.{{ metric.name }}</span>
                        <span class="metric-score">({{ metric.score }}分)</span>
                      </span>
                    </template>
                    <template v-else-if="pageType == 'edit'">
                      <span class="metric-name"> {{ metric.showIndex }}.{{ metric.name }}</span>
                      <span class="metric-score">({{ metric.score }}分)</span>
                    </template>
                    <template v-else>
                      <span class="metric-name"> {{ metric.showIndex }}.{{ metric.name }}</span>
                      <span class="metric-score">({{ metric.score }}分)</span>
                    </template>

                    <template v-if="pageType == 'revise'">
                      <!--look 或者 revise-->
                      <template v-if="!isEmpty(realmResults)">
                        <div
                          class="metric-extra"
                          v-if="Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) == 3"
                        >
                          <template v-if="metric.complete">
                            <div class="complete" v-if="metric.selfEvaluateScore >= metric.score">
                              <img :src="require('@src/assets/complete.png')" />{{ metric.selfEvaluateScore }}分
                            </div>
                            <div class="complete-not-other" v-else>
                              <img :src="require('@src/assets/complete_not_other.png')" />{{
                                metric.selfEvaluateScore
                              }}分
                            </div>
                          </template>
                          <template v-else>
                            <div class="complete-not"><img :src="require('@src/assets/complete_not.png')" />未评</div>
                          </template>
                        </div>
                      </template>
                    </template>
                    <template v-else-if="pageType == 'look'">
                      <template v-if="!isEmpty(realmResults)">
                        <div class="metric-extra">
                          <template v-if="metric.complete">
                            <div class="complete" v-if="metric.selfEvaluateScore >= metric.score">
                              <img :src="require('@src/assets/complete.png')" />{{ metric.selfEvaluateScore }}分
                            </div>
                            <div class="complete-not-other" v-else>
                              <img :src="require('@src/assets/complete_not_other.png')" />{{
                                metric.selfEvaluateScore
                              }}分
                            </div>
                          </template>
                          <template v-else>
                            <div class="complete-not"><img :src="require('@src/assets/complete_not.png')" />未评</div>
                          </template>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="metric-extra">
                        <template v-if="metric.complete">
                          <div class="complete" v-if="metric.selfEvaluateScore >= metric.score">
                            <img :src="require('@src/assets/complete.png')" />{{ metric.selfEvaluateScore }}分
                          </div>
                          <div class="complete-not-other" v-else>
                            <img :src="require('@src/assets/complete_not_other.png')" />{{ metric.selfEvaluateScore }}分
                          </div>
                        </template>
                        <template v-else>
                          <div class="complete-not"><img :src="require('@src/assets/complete_not.png')" />未评</div>
                        </template>
                      </div>
                    </template>
                  </div>

                  <template #extra>
                    <template v-if="pageType == 'edit'">
                      <template v-if="isSynergy">
                        <!--先判断当前领域是否存在认领列表中，在判断被认领的列表中是否是自己认领的 isCurrentUser 分为yes or no-->
                        <template v-if="realm.realmNo in claimObj">
                          <template v-if="claimObj[realm.realmNo].isCurrentUser == 'yes'">
                            <template v-if="!isRealmInResult(realm)">
                              <a-popconfirm
                                title="是否删除当前领域已填写内容?"
                                ok-text="是"
                                cancel-text="否"
                                @confirm="(e) => handleDisclaimRealmId(e, realm, 'yes')"
                                @cancel="(e) => handleDisclaimRealmId(e, realm, 'no')"
                              >
                                <a-button
                                  type="primary"
                                  danger
                                  @click="
                                    (e) => {
                                      e.stopPropagation();
                                    }
                                  "
                                >
                                  取消认领
                                </a-button>
                              </a-popconfirm>
                            </template>
                            <template v-else>
                              <a-popconfirm
                                title="取消认领后，修改的数据将不会保存！"
                                ok-text="确定"
                                cancel-text="取消"
                                @confirm="(e) => handleDisclaimRealmId(e, realm, 'yes')"
                              >
                                <a-button
                                  type="primary"
                                  danger
                                  @click="
                                    (e) => {
                                      e.stopPropagation();
                                    }
                                  "
                                >
                                  取消认领
                                </a-button>
                              </a-popconfirm>
                            </template>
                          </template>
                          <template v-else>
                            <a-tooltip placement="top">
                              <template #title>
                                <span>{{ claimObj[realm.realmNo].claimUserName }}正在协作中…</span>
                              </template>
                              <div class="claim-username">{{ claimObj[realm.realmNo].claimUserName }}正在协作中…</div>
                            </a-tooltip>
                          </template>
                        </template>
                        <template v-else>
                          <a-button type="primary" @click="(e) => handleClaimRealmId(e, realm)"> 领域认领 </a-button>
                        </template>
                      </template>
                    </template>
                    <template
                      v-else-if="
                        pageType == 'revise' &&
                        !isEmpty(realmResults) &&
                        Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) == 3
                      "
                    >
                      <template v-if="isSynergy">
                        <!--先判断当前领域是否存在认领列表中，在判断被认领的列表中是否是自己认领的 isCurrentUser 分为yes or no-->
                        <template v-if="realm.realmNo in claimObj">
                          <template v-if="claimObj[realm.realmNo].isCurrentUser == 'yes'">
                            <template v-if="!isRealmInResult(realm)">
                              <a-popconfirm
                                title="是否删除当前领域已填写内容?"
                                ok-text="是"
                                cancel-text="否"
                                @confirm="(e) => handleDisclaimRealmId(e, realm, 'yes')"
                                @cancel="(e) => handleDisclaimRealmId(e, realm, 'no')"
                              >
                                <a-button
                                  type="primary"
                                  danger
                                  @click="
                                    (e) => {
                                      e.stopPropagation();
                                    }
                                  "
                                >
                                  取消认领
                                </a-button>
                              </a-popconfirm>
                            </template>
                            <template v-else>
                              <a-popconfirm
                                title="取消认领后，修改的数据将不会保存！"
                                ok-text="确定"
                                cancel-text="取消"
                                @confirm="(e) => handleDisclaimRealmId(e, realm, 'yes')"
                              >
                                <a-button
                                  type="primary"
                                  danger
                                  @click="
                                    (e) => {
                                      e.stopPropagation();
                                    }
                                  "
                                >
                                  取消认领
                                </a-button>
                              </a-popconfirm>
                            </template>
                          </template>
                          <template v-else>
                            <a-tooltip placement="top">
                              <template #title>
                                <span>{{ claimObj[realm.realmNo].claimUserName }}正在协作中…</span>
                              </template>
                              <div class="claim-username">{{ claimObj[realm.realmNo].claimUserName }}正在协作中…</div>
                            </a-tooltip>
                          </template>
                        </template>
                        <template v-else>
                          <a-button type="primary" @click="(e) => handleClaimRealmId(e, realm)"> 领域认领 </a-button>
                        </template>
                      </template>
                    </template>

                    <!--审核状态和协作没有关系-->
                    <div
                      class="realm-extra"
                      v-if="pageType == 'look' || pageType == 'revise'"
                      @click="(e) => handleRealmReject(e, realm)"
                    >
                      <template v-if="!isEmpty(realmResults)">
                        <template v-if="Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) == 4">
                          <div class="complete"><img :src="require('@src/assets/complete.png')" />已通过</div>
                        </template>
                        <template
                          v-else-if="Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) == 3"
                        >
                          <div class="complete-not-other">
                            <img :src="require('@src/assets/revise_icon.png')" />驳回理由
                          </div>
                        </template>
                        <template
                          v-else-if="Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) == 2"
                        >
                          <div class="complete-not-other">
                            <img :src="require('@src/assets/revise_icon.png')" />驳回复评
                          </div>
                        </template>
                        <template v-else>
                          <div class="complete-not"><img :src="require('@src/assets/complete_not.png')" />未审核</div>
                        </template>
                      </template>
                    </div>
                  </template>
                </a-collapse-panel>
              </a-collapse>
            </section>
          </section>
          <section class="middle-blank"></section>
          <section class="right-wrap">
            <div class="scroll-wrap" ref="right">
              <div class="project-name">
                {{ project.name }}（{{ project.regionType == 'community' ? '城镇社区' : '农村社区' }}）
              </div>
              <div>
                <div class="metric-view-name">{{ metricView.name }}({{ metricView.score }}分)</div>
                <div class="metric-view-title">一、指标说明</div>
                <div class="metric-view-text">{{ metricView.note }}</div>
                <div class="metric-view-title">二、赋分标准</div>
                <div
                  v-for="(markingNoteText, markingNoteTextIndex) in metricView.markingNoteTexts"
                  :key="markingNoteTextIndex"
                  class="metric-view-text"
                >
                  {{ markingNoteTextIndex + 1 }}.{{ markingNoteText }}
                </div>
                <div class="metric-view-title">
                  <span class="text">三、评价方式</span>
                  <a-radio-group
                    button-style="solid"
                    v-model:value="metricView.evaluateActiveKey"
                    @change="
                      (e) => {
                        metricView.evaluateActiveKey = e.target.value;
                      }
                    "
                  >
                    <a-radio-button
                      :value="valIndex"
                      v-for="(val, valKey, valIndex) in metricView.metricEvaluates"
                      :key="valIndex"
                    >
                      {{ transformKeyToTextByDictionaries(valKey) }}
                    </a-radio-button>
                  </a-radio-group>
                </div>
                <template
                  v-for="(metricEvaluateObj, metricEvaluateIndex) in metricEvaluatesByKey"
                  :key="metricEvaluateIndex"
                >
                  <div class="evaluate-note">
                    <span v-if="metricEvaluateObj.required == 'yes'"> * </span>
                    {{ metricEvaluateIndex + 1 + '.' + metricEvaluateObj.note }}
                  </div>
                  <div class="evaluate-list">
                    <div class="evaluate-list-title" v-if="metricView.showType == 'ordinary'">
                      <div class="title">佐证材料</div>

                      <template v-if="pageType == 'revise'">
                        <template v-if="isSynergy">
                          <template v-if="isCurrentUserInSynergy">
                            <a-button
                              type="primary"
                              @click="addLine(metricEvaluateObj.selfEvaluateEvidences)"
                              v-if="!isLook && realmViewCountyEvaluateHandleStatus"
                              class="button"
                            >
                              新增
                            </a-button>
                          </template>
                        </template>
                        <template v-else>
                          <a-button
                            type="primary"
                            @click="addLine(metricEvaluateObj.selfEvaluateEvidences)"
                            v-if="!isLook && realmViewCountyEvaluateHandleStatus"
                            class="button"
                          >
                            新增
                          </a-button>
                        </template>
                      </template>
                      <template v-else-if="pageType == 'edit'">
                        <template v-if="isSynergy">
                          <template v-if="isCurrentUserInSynergy">
                            <a-button
                              type="primary"
                              @click="addLine(metricEvaluateObj.selfEvaluateEvidences)"
                              v-if="!isLook"
                              class="button"
                            >
                              新增
                            </a-button>
                          </template>
                        </template>
                        <template v-else>
                          <a-button
                            type="primary"
                            @click="addLine(metricEvaluateObj.selfEvaluateEvidences)"
                            v-if="!isLook"
                            class="button"
                          >
                            新增
                          </a-button>
                        </template>
                      </template>
                    </div>

                    <div class="evaluate-list-content">
                      <div
                        v-for="(tt, ttIndex) in selfEvaluateEvidencesEmptyAdd(metricEvaluateObj.selfEvaluateEvidences)"
                        :key="ttIndex"
                        class="evaluate-item"
                      >
                        <template v-if="pageType == 'revise'">
                          <template v-if="isSynergy">
                            <template v-if="isCurrentUserInSynergy">
                              <a-textarea
                                v-model:value="tt.evidence"
                                :placeholder="
                                  metricView.showType == 'ordinary'
                                    ? '请输入佐证材料'
                                    : `请简述${metricEvaluateObj.note}`
                                "
                                :autoSize="{
                                  minRows: 6,
                                  maxRows: 6
                                }"
                                v-if="!isLook && realmViewCountyEvaluateHandleStatus"
                                :showCount="true"
                                allowClear
                              />

                              <a-textarea
                                class="textarea-look"
                                v-model:value="tt.evidence"
                                :autoSize="{
                                  minRows: 6,
                                  maxRows: 6
                                }"
                                :disabled="true"
                                v-else
                              />
                            </template>
                            <template v-else>
                              <a-textarea
                                class="textarea-look"
                                v-model:value="tt.evidence"
                                :autoSize="{
                                  minRows: 6,
                                  maxRows: 6
                                }"
                                :disabled="true"
                              />
                            </template>
                          </template>
                          <template v-else>
                            <a-textarea
                              v-model:value="tt.evidence"
                              :placeholder="
                                metricView.showType == 'ordinary' ? '请输入佐证材料' : `请简述${metricEvaluateObj.note}`
                              "
                              :autoSize="{
                                minRows: 6,
                                maxRows: 6
                              }"
                              v-if="!isLook && realmViewCountyEvaluateHandleStatus"
                              :showCount="true"
                              allowClear
                            />

                            <a-textarea
                              class="textarea-look"
                              v-model:value="tt.evidence"
                              :autoSize="{
                                minRows: 6,
                                maxRows: 6
                              }"
                              :disabled="true"
                              v-else
                            />
                          </template>
                        </template>
                        <template v-else-if="pageType == 'edit'">
                          <template v-if="isSynergy">
                            <template v-if="isCurrentUserInSynergy">
                              <a-textarea
                                v-model:value="tt.evidence"
                                :placeholder="
                                  metricView.showType == 'ordinary'
                                    ? '请输入佐证材料'
                                    : `请简述${metricEvaluateObj.note}`
                                "
                                :autoSize="{
                                  minRows: 6,
                                  maxRows: 6
                                }"
                                v-if="!isLook"
                                :showCount="true"
                                allowClear
                              />

                              <a-textarea
                                class="textarea-look"
                                v-model:value="tt.evidence"
                                :autoSize="{
                                  minRows: 6,
                                  maxRows: 6
                                }"
                                :disabled="true"
                                v-else
                              />
                            </template>
                            <template v-else>
                              <a-textarea
                                class="textarea-look"
                                v-model:value="tt.evidence"
                                :autoSize="{
                                  minRows: 6,
                                  maxRows: 6
                                }"
                                :disabled="true"
                              />
                            </template>
                          </template>
                          <template v-else>
                            <a-textarea
                              v-model:value="tt.evidence"
                              :placeholder="
                                metricView.showType == 'ordinary' ? '请输入佐证材料' : `请简述${metricEvaluateObj.note}`
                              "
                              :autoSize="{
                                minRows: 6,
                                maxRows: 6
                              }"
                              v-if="!isLook"
                              :showCount="true"
                              allowClear
                            />
                            <a-textarea
                              class="textarea-look"
                              v-model:value="tt.evidence"
                              :autoSize="{
                                minRows: 6,
                                maxRows: 6
                              }"
                              :disabled="true"
                              v-else
                            />
                          </template>
                        </template>
                        <template v-else>
                          <a-textarea
                            class="textarea-look"
                            v-model:value="tt.evidence"
                            :autoSize="{
                              minRows: 6,
                              maxRows: 6
                            }"
                            :disabled="true"
                          />
                        </template>

                        <div class="file-gourp-wrap">
                          <template v-if="pageType == 'revise'">
                            <template v-if="isSynergy">
                              <template v-if="isCurrentUserInSynergy">
                                <a-upload
                                  :file-list="tt.fileList"
                                  :multiple="true"
                                  :customRequest="(value) => uploadFiles(value, tt)"
                                  :showUploadList="{
                                    showPreviewIcon: false,
                                    showRemoveIcon: false,
                                    showDownloadIcon: false
                                  }"
                                  :before-upload="onBeforeUpload"
                                >
                                  <a-button
                                    type="primary"
                                    v-if="
                                      tt.fileList.length < fileLimit && !isLook && realmViewCountyEvaluateHandleStatus
                                    "
                                  >
                                    上传附件
                                  </a-button>
                                </a-upload>
                              </template>
                            </template>
                            <template v-else>
                              <a-upload
                                :file-list="tt.fileList"
                                :multiple="true"
                                :customRequest="(value) => uploadFiles(value, tt)"
                                :showUploadList="{
                                  showPreviewIcon: false,
                                  showRemoveIcon: false,
                                  showDownloadIcon: false
                                }"
                                :before-upload="onBeforeUpload"
                              >
                                <a-button
                                  type="primary"
                                  v-if="
                                    tt.fileList.length < fileLimit && !isLook && realmViewCountyEvaluateHandleStatus
                                  "
                                >
                                  上传附件
                                </a-button>
                              </a-upload>
                            </template>
                          </template>
                          <template v-else-if="pageType == 'edit'">
                            <template v-if="isSynergy">
                              <template v-if="isCurrentUserInSynergy">
                                <a-upload
                                  :file-list="tt.fileList"
                                  :multiple="true"
                                  :customRequest="(value) => uploadFiles(value, tt)"
                                  :showUploadList="{
                                    showPreviewIcon: false,
                                    showRemoveIcon: false,
                                    showDownloadIcon: false
                                  }"
                                  :before-upload="onBeforeUpload"
                                >
                                  <a-button type="primary" v-if="tt.fileList.length < fileLimit && !isLook">
                                    上传附件
                                  </a-button>
                                </a-upload>
                              </template>
                            </template>
                            <template v-else>
                              <a-upload
                                :file-list="tt.fileList"
                                :multiple="true"
                                :customRequest="(value) => uploadFiles(value, tt)"
                                :showUploadList="{
                                  showPreviewIcon: false,
                                  showRemoveIcon: false,
                                  showDownloadIcon: false
                                }"
                                :before-upload="onBeforeUpload"
                              >
                                <a-button type="primary" v-if="tt.fileList.length < fileLimit && !isLook">
                                  上传附件
                                </a-button>
                              </a-upload>
                            </template>
                          </template>

                          <div class="file-wrap">
                            <div class="file-item" v-for="(file, fileIndex) in tt.fileList" :key="fileIndex">
                              <div class="file-item-flex">
                                <span class="file-name"> {{ fileIndex + 1 }}.{{ file.name }} </span>
                                <span v-if="!isImage(file)" class="file-preview" @click="goPreview(file)">
                                  在线预览
                                </span>
                                <span class="file-down" @click="handleDownload(file)">立即下载</span>

                                <template v-if="pageType == 'revise'">
                                  <template v-if="isSynergy">
                                    <template v-if="isCurrentUserInSynergy">
                                      <span
                                        v-if="!isLook && realmViewCountyEvaluateHandleStatus"
                                        class="file-delete"
                                        @click="deleteFile(tt.fileList, fileIndex)"
                                      >
                                        <DeleteOutlined
                                          :style="{
                                            fontSize: '20px',
                                            color: '#c5c5c5'
                                          }"
                                        />
                                      </span>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <span
                                      v-if="!isLook && realmViewCountyEvaluateHandleStatus"
                                      class="file-delete"
                                      @click="deleteFile(tt.fileList, fileIndex)"
                                    >
                                      <DeleteOutlined
                                        :style="{
                                          fontSize: '20px',
                                          color: '#c5c5c5'
                                        }"
                                      />
                                    </span>
                                  </template>
                                </template>
                                <template v-else-if="pageType == 'edit'">
                                  <template v-if="isSynergy">
                                    <template v-if="isCurrentUserInSynergy">
                                      <span
                                        v-if="!isLook"
                                        class="file-delete"
                                        @click="deleteFile(tt.fileList, fileIndex)"
                                      >
                                        <DeleteOutlined
                                          :style="{
                                            fontSize: '20px',
                                            color: '#c5c5c5'
                                          }"
                                        />
                                      </span>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <span
                                      v-if="!isLook"
                                      class="file-delete"
                                      @click="deleteFile(tt.fileList, fileIndex)"
                                    >
                                      <DeleteOutlined
                                        :style="{
                                          fontSize: '20px',
                                          color: '#c5c5c5'
                                        }"
                                      />
                                    </span>
                                  </template>
                                </template>
                              </div>
                              <div class="file-image" v-if="isImage(file)">
                                <a-image :width="120" :src="file.url" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="evaluate-delete-wrap" v-if="metricView.showType == 'ordinary'">
                          <a-popconfirm
                            placement="left"
                            ok-text="确定"
                            cancel-text="取消"
                            @confirm="deleteLine(metricEvaluateObj.selfEvaluateEvidences, ttIndex)"
                          >
                            <template #title> 你确定要删除该佐证材料？ </template>
                            <template v-if="pageType == 'revise'">
                              <template v-if="isSynergy">
                                <template v-if="isCurrentUserInSynergy">
                                  <a-button
                                    type="primary"
                                    danger
                                    v-if="!isLook && realmViewCountyEvaluateHandleStatus && ttIndex > 0"
                                    >删除</a-button
                                  >
                                </template>
                              </template>
                              <template v-else>
                                <a-button
                                  type="primary"
                                  danger
                                  v-if="!isLook && realmViewCountyEvaluateHandleStatus && ttIndex > 0"
                                  >删除</a-button
                                >
                              </template>
                            </template>
                            <template v-else-if="pageType == 'edit'">
                              <template v-if="isSynergy">
                                <template v-if="isCurrentUserInSynergy">
                                  <a-button type="primary" danger v-if="!isLook && ttIndex > 0">删除</a-button>
                                </template>
                              </template>
                              <template v-else>
                                <a-button type="primary" danger v-if="!isLook && ttIndex > 0">删除</a-button>
                              </template>
                            </template>
                          </a-popconfirm>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </section>
        </section>
        <section class="footer-wrap">
          <div class="footer-flex">
            <div class="footer-left">{{ metricView.showIndex }}.{{ metricView.name }}</div>
            <div class="footer-middle">
              <div class="current-score-line">
                <span class="label">自评得分：</span>
                <template v-if="pageType == 'revise'">
                  <template v-if="isSynergy">
                    <template v-if="isCurrentUserInSynergy">
                      <a-input-number
                        class="number"
                        v-model:value="metricView.selfEvaluateScore"
                        :min="0"
                        :max="metricView.score"
                        @blur="calculationData"
                        v-if="!isLook && realmViewCountyEvaluateHandleStatus"
                      />
                      <span class="number-look" v-else>{{ metricView.selfEvaluateScore }}</span>
                    </template>
                    <template v-else>
                      <span class="number-look">{{ metricView.selfEvaluateScore }}</span>
                    </template>
                  </template>
                  <template v-else>
                    <a-input-number
                      class="number"
                      v-model:value="metricView.selfEvaluateScore"
                      :min="0"
                      :max="metricView.score"
                      @blur="calculationData"
                      v-if="!isLook && realmViewCountyEvaluateHandleStatus"
                    />
                    <span class="number-look" v-else>{{ metricView.selfEvaluateScore }}</span>
                  </template>
                </template>
                <template v-else-if="pageType == 'look'">
                  <span class="number-look">{{ metricView.selfEvaluateScore }}</span>
                </template>
                <template v-else>
                  <!--pageType == 'edit'-->
                  <template v-if="isSynergy">
                    <template v-if="isCurrentUserInSynergy">
                      <a-input-number
                        class="number"
                        v-model:value="metricView.selfEvaluateScore"
                        :min="0"
                        :max="metricView.score"
                        @blur="calculationData"
                        v-if="!isLook"
                      />
                      <span class="number-look" v-else>{{ metricView.selfEvaluateScore }}</span>
                    </template>
                    <template v-else>
                      <span class="number-look">{{ metricView.selfEvaluateScore }}</span>
                    </template>
                  </template>
                  <template v-else>
                    <a-input-number
                      class="number"
                      v-model:value="metricView.selfEvaluateScore"
                      :min="0"
                      :max="metricView.score"
                      @blur="calculationData"
                      v-if="!isLook"
                    />
                    <span class="number-look" v-else>{{ metricView.selfEvaluateScore }}</span>
                  </template>
                </template>

                <span class="mark">分</span>
                <span class="max">（最高{{ metricView.score }}分）</span>
              </div>
              <div class="current-score-line">
                <span class="label">自评总得分：</span>
                <span class="number-look">{{ Number(Number(selfEvaluateScore).toFixed(2)) }}</span>
                <span class="mark">分</span>
              </div>
            </div>
            <div class="footer-right">
              <div class="right-button-line">
                <div class="footer-button">
                  <a-button @click="preMetric" size="large" block>上一项</a-button>
                </div>
                <div class="footer-button">
                  <a-button @click="nextMetric" size="large" block>下一项</a-button>
                </div>
                <div class="footer-button" v-if="pageType == 'edit'">
                  <template v-if="isSynergy">
                    <template v-if="isCurrentUserInSynergy">
                      <a-button v-if="!isLook" size="large" block @click="isLook = true">立即预览</a-button>
                      <a-button v-if="isLook" size="large" block type="primary" @click="isLook = false">
                        返回
                      </a-button>
                    </template>
                  </template>
                  <template v-else>
                    <a-button v-if="!isLook" size="large" block @click="isLook = true">立即预览</a-button>
                    <a-button v-if="isLook" size="large" block type="primary" @click="isLook = false"> 返回 </a-button>
                  </template>
                </div>
              </div>
              <template v-if="pageType == 'edit'">
                <template v-if="isSynergy">
                  <template v-if="isCurrentUserInSynergy">
                    <div class="right-button-line" v-if="!isLook">
                      <div class="footer-button">
                        <a-button type="primary" block size="large" @click="handleApiPostTemporary(true)"
                          >保存</a-button
                        >
                      </div>
                      <div class="footer-button">
                        <a-button block size="large" @click="handleCompleteClaimContent">完成协作</a-button>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="right-button-line" v-if="!isLook">
                    <div class="footer-button">
                      <a-button type="primary" block size="large" @click="handleApiPostTemporary(true)" v-preReClick
                        >保存</a-button
                      >
                    </div>
                    <div class="footer-button">
                      <a-button type="primary" block size="large" @click="handleCompleteClaimContent" v-preReClick
                        >完成</a-button
                      >
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="pageType == 'revise'">
                <template v-if="isSynergy">
                  <template v-if="isCurrentUserInSynergy">
                    <div class="right-button-line">
                      <div class="footer-button">
                        <a-button type="primary" block size="large" @click="handleApiPostTemporary(true)"
                          >保存</a-button
                        >
                      </div>
                      <div class="footer-button">
                        <a-button block size="large" @click="handleCompleteClaimContent">完成协作</a-button>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="right-button-line">
                    <div class="footer-button">
                      <a-button type="primary" block size="large" @click="handleApiPostTemporary(true)" v-preReClick
                        >保存</a-button
                      >
                    </div>
                    <div class="footer-button">
                      <a-button type="primary" block size="large" @click="handleCompleteClaimContent" v-preReClick
                        >完成</a-button
                      >
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </section>
      </section>

      <previewFile :file="activeFile" v-if="visibleModalFile" @onCancel="onCancelModalFile"></previewFile>

      <mp3or4
        :fileUrl="activeFileUrl"
        :fileType="activeFileType"
        v-if="visibleModalMp"
        @onCancel="visibleModalMp = false"
      ></mp3or4>

      <remarkModal
        :operationLogs="operationLogs"
        v-if="visibleModalRemark"
        @onCancel="visibleModalRemark = false"
      ></remarkModal>
    </section>
  </a-spin>
</template>

<script>
import { isEmpty } from 'lodash';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import { CaretRightOutlined, CheckCircleFilled, DeleteOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import {
  apiGetProjectDetail,
  apiGetProjectDetailOutTime,
  apiGetProjectDetailByLook,
  apiPostTemporary,
  apiPostSave,
  apiPostSaveRevise,
  changeCommunityOperateType,
  getClaimList,
  claimRealmId,
  disclaimRealmId,
  // apiPostSelfEvaluate,
  apiPostSaveRevisezc,
  apiPostDeclareCompleteClaimContent,
  apiPostAmendCompleteClaimContent
} from '@src/apis/community';
import { previewPdfApi } from '@src/apis/index';
import { downloadFile2 } from '@src/utils';

import { previewFile, mp3or4 } from '@src/components';
import remarkModal from './components/remarkModal/index';
export default {
  data() {
    return {
      activeFileUrl: '',
      activeFileType: '',
      visibleModalMp: false,
      //
      visibleModalRemark: false,
      operationLogs: '', //此处本来为string,改为list
      isEmpty,
      //
      collapseArrow: {
        color: '#C5C5C5'
      },
      //
      collapseActiveKey: [],
      projectId: '',
      project: {},
      projectResult: {},
      projectResultId: '',
      temporaryJsonGroupByRealm: '',
      activeMetricIdToKeyBySelf: '',
      minMetricIdToKeyBySelf: '',
      maxMetricIdToKeyBySelf: '',
      isLook: false, //是否是查看状态 (20230309 区分 1,2自评修改(isLook再预览模式) 3查看 4驳回修改(按需开启isLook) )
      previewFlag: false, //预览
      fileLimit: 6, //佐证每个的个数限制
      selfEvaluateScore: 0,
      requestFlag: false,
      pageType: 'edit', //edit(自评及自评修改) look(查看详情)  revise(驳回修改)
      activeFile: {},
      visibleModalFile: false,
      realmsList: [], //领域数组
      realmResults: {},
      tipText: '加载中......',
      spinning: false,
      outTime: false,
      processStatus: 1,
      claimObj: {}, //认领列表，但是为map的对象
      claimObjTime: null,
      registerOldClaimList: [], //记录前后2次认领列表中，从1=》0 并且是别人的领域
      realmOperationLogs: '', //审核记录Map
      completeProgress: 0
    };
  },
  components: {
    CaretRightOutlined,
    CheckCircleFilled,
    DeleteOutlined,
    LoadingOutlined,
    previewFile,
    mp3or4,
    remarkModal
  },
  created() {
    const _this = this;

    _this.pageType = _this.$route.query.type || '';
    _this.outTime = _this.$route.query.outTime || '';
    if (_this.pageType == 'look' || _this.pageType == 'revise') {
      if (_this.outTime) {
        //超出规定时间的
        _this.projectId = _this.$route.query.id;
      } else {
        _this.projectResultId = _this.$route.query.id;
      }
    } else {
      _this.projectId = _this.$route.query.id;
      //获取暂存
    }

    _this.$nextTick(async () => {
      await _this.getDetails();
    });
  },

  beforeUnmount() {
    clearInterval(this.claimObjTime);
    this.claimObjTime = null;
  },
  computed: {
    ...mapGetters(['userinfo', 'dictionaries', 'token']),
    //协作模式下，完成了协作，并且是主账户
    isSynergyAndFinishAndMain() {
      return this.isSynergy && this.userinfo.accountGrade == 'main' && this.completeProgress == 100;
    },

    //判断是否是协作模式下
    isSynergy() {
      return this.communityOperateType;
    },
    //协作模式下，自己的领域,右侧和底部用
    isCurrentUserInSynergy() {
      let isCurrentUserInSynergyText = false;
      if (
        this.isSynergy &&
        this.realmView.realmNo in this.claimObj &&
        this.claimObj[this.realmView.realmNo].isCurrentUser == 'yes'
      ) {
        isCurrentUserInSynergyText = true;
      } else {
        isCurrentUserInSynergyText = false;
      }
      return isCurrentUserInSynergyText;
    },
    //协作模式。。
    canChangeCommunityOperateType() {
      //是否可以开启协作模式，（后台判断，如没有子账号，或者时间过了）no  yes 转义为  false or  true
      return this.project.canChangeCommunityOperateType == 'yes' ? true : false;
    },
    communityOperateType() {
      //默认值为单人 single    synergy 转义为  false or true
      return this.project.communityOperateType == 'synergy' ? true : false;
    },
    realmViewCountyEvaluateHandleStatus() {
      //驳回可编辑
      return (
        !isEmpty(this.realmResults) &&
        !isEmpty(this.realmView) &&
        Number(this.realmResults[Number(this.realmView.realmNo)].countyEvaluateHandleStatus) == 3
      );
    },
    evaluateType() {
      //评价类型
      return this.dictionaries.evaluateType || [];
    },
    realmView() {
      //当前激活的领域
      let realmObject = {};
      if (this.realmsList && this.realmsList.length > 0) {
        this.realmsList.forEach((realm, realmIndex) => {
          realm.metrics.forEach((metric, metricIndex) => {
            if (metric.metricIdToKeyBySelf == this.activeMetricIdToKeyBySelf) {
              realmObject = Object.assign(realm, { realmIndex });
            }
          });
        });
      }
      return realmObject;
    },
    metricView() {
      //当前激活的指标
      let metricObject = {};
      if (this.realmsList && this.realmsList.length > 0) {
        this.realmsList.forEach((realm, realmIndex) => {
          realm.metrics.forEach((metric, metricIndex) => {
            if (metric.metricIdToKeyBySelf == this.activeMetricIdToKeyBySelf) {
              metricObject = metric;
            }
          });
        });
      }

      return metricObject;
    },
    metricEvaluatesByKey() {
      //激活的当前标的激活的评价类型的评价列表数据
      let metricEvaluatesByFilter = [];
      if (this.metricView && this.metricView.metricEvaluates) {
        let evaluateActiveKey = this.metricView.evaluateActiveKey || 0;
        let keys = Object.keys(this.metricView.metricEvaluates) || [];
        let key = keys[evaluateActiveKey];
        metricEvaluatesByFilter = this.metricView.metricEvaluates[key];
      }

      return metricEvaluatesByFilter;
    }
  },
  methods: {
    isRealmInResult(realm) {
      //领域
      let flag = false;
      let metrics = realm.metrics;
      let { metricResults } = this.projectResult;
      metrics.forEach((item, index) => {
        if (item.metricNo in metricResults) {
          flag = true;
        }
      });
      return flag;
    },
    async restartTimer() {
      try {
        const _this = this;
        clearInterval(_this.claimObjTime);
        _this.claimObjTime = null;
        // _this.claimObjTime = setInterval(async () => {
        //   await _this.handleGetClaimList();
        // }, 60000 * 5);
        // await _this.handleGetClaimList();
      } catch (error) {
        console.log(error);
      }
    },
    //指标切换后的操作
    async handleMetricAfter() {
      try {
        const _this = this;
        this.handleApiPostTemporary(false);
        _this.$refs.right.scrollTop = 0;
        if (_this.isSynergy) {
          if (_this.registerOldClaimList.indexOf(this.realmView.realmNo) != -1) {
            //当前指标存在刷新列表中，要刷新
            _this.registerOldClaimList = [];
            clearInterval(_this.claimObjTime);
            _this.claimObjTime = null;
            _this.tipText = '有数据变动，刷新中';
            _this.spinning = true;
            await _this.getDetails();
            _this.spinning = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    //取消认领
    async handleDisclaimRealmId(e, realm, deleteCache) {
      const _this = this;
      e.stopPropagation();
      if (deleteCache == 'no') {
        this.handleApiPostTemporary(false);
      }
      const { data, code } = await disclaimRealmId({
        realmId: realm.realmId,
        processStatus: _this.processStatus || 1,
        deleteCache
      });

      if (code === '00000') {
        message.success('取消成功');
        _this.restartTimer();
        // _this.refreshPageData();
        _this.getDetails();
      } else {
        // message.error('取消失败');
      }
    },
    //认领
    async handleClaimRealmId(e, realm) {
      const _this = this;
      e.stopPropagation();
      this.handleApiPostTemporary(false);
      const { data, code } = await claimRealmId({
        realmId: realm.realmId,
        processStatus: _this.processStatus || 1
      });

      if (code === '00000') {
        message.success('认领成功');
        _this.restartTimer();
        _this.refreshPageData();
      } else {
        // message.error('认领失败');
      }
    },
    //获取认领列表
    async handleGetClaimList() {
      try {
        const _this = this;
        if (_this.isSynergy) {
          //如果是可以开启协同，并且，为协同模式下,才调用获取认领列表接口
          const { data, code } = await getClaimList({
            processStatus: _this.processStatus || 1,
            projectId: _this.projectId
          });
          Object.keys(_this.claimObj).forEach((key) => {
            if (!(key in data) && _this.claimObj[key].isCurrentUser == 'no') {
              _this.registerOldClaimList.push(key);
            }
          });
          _this.claimObj = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //改变模式，单人或者协作
    async handleChangeCommunityOperateType(communityOperateType) {
      try {
        const _this = this;
        if (communityOperateType == 'single') {
          //取消的时候要判断是否有人在干活
        }

        const { data, code } = await changeCommunityOperateType({
          projectId: _this.project.projectId || _this.projectId,
          communityOperateType: communityOperateType
        });

        if (code === '00000') {
          message.success('操作成功');
          _this.getDetails();
        } else {
          // message.error('操作失败');
        }
      } catch (error) {
        console.log(error);
      }
    },
    //刷新页面数据
    async refreshPageData() {
      try {
        const _this = this;
        let api = '';
        let param = '';
        if (_this.pageType == 'look' || _this.pageType == 'revise') {
          if (_this.outTime) {
            param = _this.projectId;
            api = apiGetProjectDetailOutTime;
          } else {
            param = _this.projectResultId;
            api = apiGetProjectDetailByLook;
          }
        } else {
          param = _this.projectId;
          api = apiGetProjectDetail;
        }
        _this.spinning = true;
        const { code, data } = await api(param);
        _this.spinning = false;
        if (code === '00000' && data) {
          console.log(data);
          const { project, projectResult, temporaryJsonGroupByRealm, realmResults, processStatus, realmOperationLogs } =
            data;
          const { realms } = project;
          _this.project = project; //干净的从接口拿的数据
          _this.projectResult = projectResult;
          _this.completeProgress = projectResult.completeProgress;
          _this.temporaryJsonGroupByRealm = temporaryJsonGroupByRealm || {};
          _this.processStatus = processStatus;
          _this.realmOperationLogs = realmOperationLogs;

          //初始化后再去获取认领列表

          if (_this.isSynergy && (_this.pageType == 'edit' || _this.pageType == 'revise')) {
            _this.claimObjTime = setInterval(async () => {
              await _this.handleGetClaimList();
            }, 60000);
            await _this.handleGetClaimList();
          }

          //目前定的是自评或者自评修改的时候有暂存
          if (temporaryJsonGroupByRealm) {
            let defaultRealms = JSON.parse(JSON.stringify(_this.realmsList));
            console.log('执行了暂存');
            try {
              let temporaryJsonMap = temporaryJsonGroupByRealm;
              let tempRealms = [];
              Object.keys(temporaryJsonMap).forEach((key) => {
                tempRealms.push(JSON.parse(temporaryJsonMap[key]));
              });

              if (_this.isSynergy && _this.pageType == 'edit') {
                tempRealms = tempRealms.filter((item) => {
                  return item.realmNo in _this.claimObj && _this.claimObj[item.realmNo].isCurrentUser == 'yes';
                });
              }
              console.log(tempRealms);
              let tempRealmsMap = {};
              tempRealms.forEach((item) => {
                tempRealmsMap[item.realmNo] = { ...item };
              });

              _this.realmsList.forEach((original, originalIndex) => {
                if (original.realmNo in tempRealmsMap) {
                  let tempMetrics = tempRealmsMap[original.realmNo].metrics;
                  original.metrics.forEach((originalMetric, originalMetricIndex) => {
                    let tempMetric = tempMetrics[originalMetricIndex];
                    originalMetric.complete = tempMetric.complete;
                    originalMetric.selfEvaluateScore = tempMetric.selfEvaluateScore;

                    let tempMetricEvaluates = tempMetric.metricEvaluates;

                    Object.keys(originalMetric.metricEvaluates).forEach((key) => {
                      let tempMetricEvaluate = tempMetricEvaluates[key]; //
                      originalMetric.metricEvaluates[key].forEach((selfEvaluateEvidence, selfEvaluateEvidenceIndex) => {
                        selfEvaluateEvidence.selfEvaluateEvidences =
                          tempMetricEvaluate[selfEvaluateEvidenceIndex].selfEvaluateEvidences;
                      });
                    });
                  });
                }
              });

              // throw new Error('error');
            } catch (error) {
              console.error('暂存数据读取异常', error);
              _this.realmsList = defaultRealms;
            }
            //取了暂存的执行一次
            _this.calculationData(); //计算每个标相加的总得分
          }
          if (_this.pageType == 'edit') {
            if (!isEmpty(projectResult.metricResults)) {
              _this.setProjectData();
            }
          }
          if (_this.pageType == 'look' || _this.pageType == 'revise') {
            console.log('查看详情或者驳回修改');
            //查看详情或者驳回修改 realmResults
            _this.realmResults = realmResults;
            _this.selfEvaluateScore = projectResult.selfEvaluateScore;
            if (!isEmpty(projectResult.metricResults)) {
              _this.setProjectData();
            }

            if (projectResult.projectResultId) {
              _this.projectResultId = projectResult.projectResultId;
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    /*
     *获取详情
     */
    async getDetails() {
      try {
        const _this = this;
        let api = '';
        let param = '';
        if (_this.pageType == 'look' || _this.pageType == 'revise') {
          if (_this.outTime) {
            param = _this.projectId;
            api = apiGetProjectDetailOutTime;
          } else {
            param = _this.projectResultId;
            api = apiGetProjectDetailByLook;
          }
        } else {
          param = _this.projectId;
          api = apiGetProjectDetail;
        }
        _this.spinning = true;
        const { code, data } = await api(param);
        _this.spinning = false;
        if (code === '00000' && data) {
          console.log(data);
          const { project, projectResult, temporaryJsonGroupByRealm, realmResults, processStatus, realmOperationLogs } =
            data;
          const { realms } = project;
          let metricIdToKeyBySelf = 0; //自己定义的 选中每个标的key值
          _this.realmsList = realms.map((item) => {
            //增加默认的selfEvaluateEvidences评价列表
            item.metrics = item.metrics.map((sub) => {
              for (let key in sub.metricEvaluates) {
                for (let i = 0; i < sub.metricEvaluates[key].length; i++) {
                  sub.metricEvaluates[key][i].selfEvaluateEvidences = [];
                }
              }
              metricIdToKeyBySelf++;
              return {
                metricIdToKeyBySelf: metricIdToKeyBySelf,
                evaluateActiveKey: 0, //每个表里面的评价方式的选中key 0|| 1
                ...sub
              };
            });
            return {
              ...item
            };
          });
          _this.project = project; //干净的从接口拿的数据
          _this.projectResult = projectResult;
          _this.completeProgress = projectResult.completeProgress;
          _this.temporaryJsonGroupByRealm = temporaryJsonGroupByRealm || {};
          _this.processStatus = processStatus;
          _this.realmOperationLogs = realmOperationLogs;
          //激活默认选中
          _this.$nextTick(() => {
            _this.setCollapseActiveKey();
            _this.activeMetricIdToKeyBySelf = _this.realmsList[0].metrics[0].metricIdToKeyBySelf;
            _this.setMinAndMaxMetricIdToKeyBySelf();
          });
          //初始化后再去获取认领列表

          if (_this.isSynergy && (_this.pageType == 'edit' || _this.pageType == 'revise')) {
            if (_this.pageType == 'revise') {
              _this.projectId = project.projectId;
            }
            _this.claimObjTime = setInterval(async () => {
              await _this.handleGetClaimList();
            }, 60000);
            await _this.handleGetClaimList();
          }

          //目前定的是自评或者自评修改的时候有暂存
          if (temporaryJsonGroupByRealm) {
            let defaultRealms = JSON.parse(JSON.stringify(_this.realmsList));
            console.log('执行了暂存');
            try {
              let temporaryJsonMap = temporaryJsonGroupByRealm;

              let tempRealms = [];
              Object.keys(temporaryJsonMap).forEach((key) => {
                tempRealms.push(JSON.parse(temporaryJsonMap[key]));
              });

              if (_this.isSynergy && _this.pageType == 'edit') {
                tempRealms = tempRealms.filter((item) => {
                  return item.realmNo in _this.claimObj && _this.claimObj[item.realmNo].isCurrentUser == 'yes';
                });
              }
              console.log(tempRealms);
              let tempRealmsMap = {};
              tempRealms.forEach((item) => {
                tempRealmsMap[item.realmNo] = { ...item };
              });

              _this.realmsList.forEach((original, originalIndex) => {
                if (original.realmNo in tempRealmsMap) {
                  let tempMetrics = tempRealmsMap[original.realmNo].metrics;
                  original.metrics.forEach((originalMetric, originalMetricIndex) => {
                    let tempMetric = tempMetrics[originalMetricIndex];
                    originalMetric.complete = tempMetric.complete;
                    originalMetric.selfEvaluateScore = tempMetric.selfEvaluateScore;

                    let tempMetricEvaluates = tempMetric.metricEvaluates;

                    Object.keys(originalMetric.metricEvaluates).forEach((key) => {
                      let tempMetricEvaluate = tempMetricEvaluates[key]; //
                      originalMetric.metricEvaluates[key].forEach((selfEvaluateEvidence, selfEvaluateEvidenceIndex) => {
                        selfEvaluateEvidence.selfEvaluateEvidences =
                          tempMetricEvaluate[selfEvaluateEvidenceIndex].selfEvaluateEvidences;
                      });
                    });
                  });
                }
              });

              // throw new Error('error');
            } catch (error) {
              console.error('暂存数据读取异常', error);
              _this.realmsList = defaultRealms;
            }
            //取了暂存的执行一次
            _this.calculationData(); //计算每个标相加的总得分
          }
          if (_this.pageType == 'edit') {
            if (!isEmpty(projectResult.metricResults)) {
              _this.setProjectData();
            }
          }
          if (_this.pageType == 'look' || _this.pageType == 'revise') {
            console.log('查看详情或者驳回修改');
            _this.selfEvaluateScore = projectResult.selfEvaluateScore;
            if (!isEmpty(projectResult.metricResults)) {
              _this.setProjectData();
            }

            if (projectResult.projectResultId) {
              _this.projectResultId = projectResult.projectResultId;
            }
            //查看详情或者驳回修改 realmResults
            _this.realmResults = realmResults;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    //设置获取来的数据到页面
    setProjectData() {
      const _this = this;
      console.log('执行了设置数据');
      let { metricResults } = _this.projectResult;
      _this.realmsList.forEach((item, index) => {
        //域
        item.metrics.forEach((metrics, metricsIndex) => {
          //指标
          let metricNo = metrics.metricNo; // 指标编号

          Object.keys(metricResults).forEach((key) => {
            //结果指标对象遍历

            if (key == metricNo && !(item.realmNo in _this.temporaryJsonGroupByRealm)) {
              // 记录的指标和数据对应
              metrics.selfEvaluateScore = metricResults[key].selfEvaluateScore;
              let evaluationMethodObject = metrics.metricEvaluates; //原始的评价方式

              Object.keys(evaluationMethodObject).forEach((metricEvaluatesKey) => {
                //
                evaluationMethodObject[metricEvaluatesKey].forEach((evaluationMethodObjectItem) => {
                  //每个评价方式
                  let metricEvaluateResultsSub = metricResults[key].metricEvaluateResults; // 结果评价方式对象

                  Object.keys(metricEvaluateResultsSub).forEach((metricEvaluateResultsSubKey) => {
                    if (metricEvaluateResultsSubKey == evaluationMethodObjectItem.metricEvaluateNo) {
                      let selfEvaluateEvidences =
                        metricEvaluateResultsSub[metricEvaluateResultsSubKey].selfEvaluateEvidences;

                      selfEvaluateEvidences = selfEvaluateEvidences.map((selfEvaluateEvidencesItem) => {
                        //评价方式佐证材料或者亮点 赋值
                        let fileList = selfEvaluateEvidencesItem.attachmentFiles;

                        fileList = fileList.map((file) => {
                          return {
                            uid: file.id,
                            name: file.fileName,
                            status: 'done',
                            response: 'Server Error 500',
                            url: file.url
                          };
                        });

                        return {
                          evidence: selfEvaluateEvidencesItem.evidence,
                          attachment: selfEvaluateEvidencesItem.attachment.split(','),
                          fileList: fileList
                        };
                      });
                      evaluationMethodObjectItem.selfEvaluateEvidences = selfEvaluateEvidences;
                    }
                  });
                });
              });
            }
          });
        });
      });
      //设置完数据计算一次
      _this.calculationData();
    },
    //执行检查是否完全 + 分数
    calculationData() {
      const _this = this;
      _this.chenckQuota();
      _this.calculationAllScore();
    },
    //计算每个标相加的总分数
    calculationAllScore() {
      try {
        const _this = this;
        let metricsList = [];
        let realmsList = _this.realmsList;

        realmsList.forEach((item) => {
          metricsList = metricsList.concat(item.metrics);
        });
        let score = 0;
        metricsList.forEach((item) => {
          score += Number(item.selfEvaluateScore || 0);
        });
        _this.selfEvaluateScore = Number(Number(score).toFixed(2));
      } catch (error) {
        console.log(error);
      }
    },
    //点击计算是否完成,不管是不是只有现场查看，统一判断 分数+里面的评价方式的必填校验
    chenckQuota() {
      try {
        const _this = this;
        let realmsList = _this.realmsList;

        realmsList.forEach((currentRealm) => {
          currentRealm.metrics.forEach((currentZb) => {
            let complete = true;
            if (!currentZb.selfEvaluateScore && currentZb.selfEvaluateScore !== 0) {
              //没填自评分
              complete = false; //没完整
            }
            //评价对象
            let metricEvaluatesList = [];
            Object.keys(currentZb.metricEvaluates).forEach((key) => {
              metricEvaluatesList = metricEvaluatesList.concat(currentZb.metricEvaluates[key]);
            });

            metricEvaluatesList.forEach((item) => {
              if (item.required == 'yes') {
                //说明是必填项

                if (!item.selfEvaluateEvidences || item.selfEvaluateEvidences.length <= 0) {
                  complete = false; //没完整
                } else {
                  //加了列表，没有填完

                  item.selfEvaluateEvidences.forEach((self) => {
                    if (!self.evidence && (!self.fileList || (self.fileList && self.fileList.length <= 0))) {
                      complete = false; //没完整
                    }
                  });
                }
              }
            });
            currentZb.complete = complete;
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    //校验和显示无关,保存前的校验，不包含只有现场查看的
    chenckQuotaComplete() {
      //保存前校验 有提示
      try {
        const _this = this;
        let list = _this.realmsList;
        if (_this.isSynergy) {
          list = list.filter((item) => {
            return item.realmNo in _this.claimObj && _this.claimObj[item.realmNo].isCurrentUser == 'yes';
          });
        }
        let allComplete = true;
        for (let i = 0; i < list.length; i++) {
          let currentRealm = list[i];
          for (let y = 0; y < currentRealm.metrics.length; y++) {
            let currentZb = currentRealm.metrics[y];
            let complete = true;
            if (!currentZb.selfEvaluateScore && currentZb.selfEvaluateScore !== 0) {
              //没填自评分
              complete = false; //没完整
              message.error(currentRealm.name + '/' + currentZb.name + '/自评分未填写');
              return false;
            }
            //评价对象
            let metricEvaluatesList = [];
            Object.keys(currentZb.metricEvaluates).forEach((key) => {
              metricEvaluatesList = metricEvaluatesList.concat(currentZb.metricEvaluates[key]);
            });

            for (let z = 0; z < metricEvaluatesList.length; z++) {
              let item = metricEvaluatesList[z];
              // && item.type != 'scene'
              if (item.required == 'yes') {
                //说明是必填项

                if (!item.selfEvaluateEvidences || item.selfEvaluateEvidences.length <= 0) {
                  complete = false; //没完整
                  let tipText = currentZb.showType == 'ordinary' ? '佐证材料未填写' : '未填写';
                  message.error(currentRealm.name + '/' + currentZb.name + '/' + item.note + tipText);
                  break;
                } else {
                  //加了列表，没有填完
                  let xx = true;
                  for (let u = 0; u < item.selfEvaluateEvidences.length; u++) {
                    let self = item.selfEvaluateEvidences[u];
                    if (!self.evidence && (!self.fileList || (self.fileList && self.fileList.length <= 0))) {
                      complete = false; //没完整
                      let tipText2 =
                        currentZb.showType == 'ordinary'
                          ? '佐证材料文本未填写或者附件未上传'
                          : '文本未填写或者附件未上传';
                      message.error(currentRealm.name + '/' + currentZb.name + '/' + item.note + tipText2);
                      xx = false;
                      break;
                    }
                  }
                  if (!xx) {
                    break;
                  }
                }
              }
            }
            if (!complete) {
              allComplete = allComplete && complete;
              return false;
            }
          }
        }

        return allComplete;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 协作模式下完成协作 +非协作也用了！
     */
    async handleCompleteClaimContent() {
      try {
        const _this = this;
        //保存前计算一次
        _this.calculationData();
        if (!_this.chenckQuotaComplete()) {
          return false;
        }
        if (_this.requestFlag) {
          return false;
        }
        _this.requestFlag = true;
        let metricResults = [];
        let realmsList = _this.realmsList;
        if (_this.isSynergy) {
          realmsList = realmsList.filter((item) => {
            return item.realmNo in _this.claimObj && _this.claimObj[item.realmNo].isCurrentUser == 'yes';
          });
        }

        realmsList.forEach((item) => {
          //驳回的时候通过的领域不需要重新提交，过滤掉
          if (_this.pageType == 'revise') {
            if (Number(_this.realmResults[Number(item.realmNo)].countyEvaluateHandleStatus) == 3) {
              //4是通过，不通过的才放进去
              metricResults = metricResults.concat(item.metrics);
            }
          } else {
            metricResults = metricResults.concat(item.metrics);
          }
        });
        let copyMetricResults = JSON.parse(JSON.stringify(metricResults));

        copyMetricResults = copyMetricResults.map((item) => {
          let metricEvaluateResults = [];

          for (let key in item.metricEvaluates) {
            for (let i = 0; i < item.metricEvaluates[key].length; i++) {
              let metricEvaluatesObj = item.metricEvaluates[key][i];
              let evaluateArray = metricEvaluatesObj.selfEvaluateEvidences;
              for (let y = 0; y < evaluateArray.length; y++) {
                let fileList = evaluateArray[y].fileList;
                if (fileList.length > 0) {
                  evaluateArray[y].attachment = _this.getFileIds(fileList);
                } else {
                  evaluateArray[y].attachment = '';
                }
                delete evaluateArray[y].fileList;
              }

              metricEvaluateResults.push({
                selfEvaluateEvidences: evaluateArray,
                metricEvaluateNo: metricEvaluatesObj.metricEvaluateNo
              });
            }
          }
          let itemSelfEvaluateScore = item.selfEvaluateScore;
          return {
            selfEvaluateScore: itemSelfEvaluateScore,
            metricNo: item.metricNo,
            metricEvaluateResults: metricEvaluateResults
          };
        });
        let sendData = {};
        let api = '';

        if (_this.pageType == 'revise') {
          sendData = {
            projectResultId: _this.projectResultId,
            metricResults: copyMetricResults
          };

          api = apiPostAmendCompleteClaimContent;
        } else {
          sendData = {
            projectId: _this.projectId,
            metricResults: copyMetricResults
          };
          api = apiPostDeclareCompleteClaimContent;
        }

        console.log(sendData);

        setTimeout(() => {
          _this.requestFlag = false;
        }, 2000);

        _this.spinning = true;

        let temSendData = {};
        let temData = _this.getTemporaryData();
        if (_this.pageType == 'revise') {
          temSendData = {
            projectResultId: _this.projectResultId,
            params: temData
          };
        }
        const { data, code } = await api({
          param: JSON.stringify(sendData),
          temporaryParam: JSON.stringify(temSendData)
        });

        _this.spinning = false;
        _this.requestFlag = false;
        if (code === '00000') {
          message.success('操作成功');
          _this.projectResultId = data;
          _this.calculationData();
          window.history.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    /*
    获取暂存数据结构体
    */
    getTemporaryData() {
      const _this = this;
      let copyRealms = JSON.parse(JSON.stringify(_this.realmsList));
      if (_this.isSynergy) {
        copyRealms = copyRealms.filter((item) => {
          return item.realmNo in _this.claimObj && _this.claimObj[item.realmNo].isCurrentUser == 'yes';
        });
      }
      // copyRealms = copyRealms.filter((item) => {
      //   //驳回的时候通过的领域不需要重新提交，过滤掉
      //   if (_this.pageType == 'revise') {
      //     return Number(_this.realmResults[Number(item.realmNo)].countyEvaluateHandleStatus) == 3;
      //   } else {
      //     return true;
      //   }
      // });

      copyRealms.forEach((item, index) => {
        //域
        item.metrics.forEach((metrics, metricsIndex) => {
          let evaluationMethodObject = metrics.metricEvaluates; //原始的评价方式
          //评价方式里的数据处理后再暂存
          Object.keys(evaluationMethodObject).forEach((metricEvaluatesKey) => {
            //
            evaluationMethodObject[metricEvaluatesKey].forEach((evaluationMethodObjectItem) => {
              let selfEvaluateEvidencesList = evaluationMethodObjectItem.selfEvaluateEvidences;
              selfEvaluateEvidencesList.forEach((selfEvaluateEvidencesItem) => {
                selfEvaluateEvidencesItem.fileList = selfEvaluateEvidencesItem.fileList.map((fileItem) => {
                  return Object.assign(
                    { ...fileItem },
                    {
                      url: `@@${fileItem.uid}@@`
                    }
                  );
                });
              });
            });
          });
        });
      });

      let copyRealmsMap = {};
      copyRealms.forEach((item) => {
        copyRealmsMap[item.realmNo] = JSON.stringify({ ...item });
      });
      return copyRealmsMap;
    },
    /**
     * 暂存
     * @param {*}
     */
    async handleApiPostTemporary(showToastFlag) {
      try {
        const _this = this;
        if (_this.pageType == 'edit' || _this.pageType == 'revise') {
          //只有社区自评的&&非预览模式才能暂存
          if (_this.isLook) {
            return false;
          } else {
            if (_this.isSynergy) {
              if (
                !(
                  _this.realmView.realmNo in _this.claimObj &&
                  _this.claimObj[_this.realmView.realmNo].isCurrentUser == 'yes'
                )
              ) {
                return false;
              }
            }
          }
        } else {
          return false;
        }

        if (_this.requestFlag) {
          return false;
        }
        _this.requestFlag = true;
        setTimeout(() => {
          _this.requestFlag = false;
        }, 2000);
        let copyRealms = JSON.parse(JSON.stringify(_this.realmsList));
        if (_this.isSynergy) {
          copyRealms = copyRealms.filter((item) => {
            return item.realmNo in _this.claimObj && _this.claimObj[item.realmNo].isCurrentUser == 'yes';
          });
        }

        copyRealms.forEach((item, index) => {
          //域
          item.metrics.forEach((metrics, metricsIndex) => {
            let evaluationMethodObject = metrics.metricEvaluates; //原始的评价方式
            //评价方式里的数据处理后再暂存
            Object.keys(evaluationMethodObject).forEach((metricEvaluatesKey) => {
              //
              evaluationMethodObject[metricEvaluatesKey].forEach((evaluationMethodObjectItem) => {
                let selfEvaluateEvidencesList = evaluationMethodObjectItem.selfEvaluateEvidences;
                selfEvaluateEvidencesList.forEach((selfEvaluateEvidencesItem) => {
                  selfEvaluateEvidencesItem.fileList = selfEvaluateEvidencesItem.fileList.map((fileItem) => {
                    return Object.assign(
                      { ...fileItem },
                      {
                        url: `@@${fileItem.uid}@@`
                      }
                    );
                  });
                });
              });
            });
          });
        });

        let copyRealmsMap = {};
        copyRealms.forEach((item) => {
          copyRealmsMap[item.realmNo] = JSON.stringify({ ...item });
        });
        let api = '';
        let sendData = {};
        if (_this.pageType == 'revise') {
          api = apiPostSaveRevisezc;

          sendData = {
            projectResultId: _this.projectResultId,
            params: copyRealmsMap
          };
        } else {
          api = apiPostTemporary;
          sendData = {
            projectId: _this.projectId,
            params: copyRealmsMap
          };
        }

        const { code } = await api(sendData);
        _this.requestFlag = false;
        if (code === '00000') {
          if (showToastFlag) {
            message.success('暂存成功');
          }

          _this.calculationData();
        } else {
          // message.error('暂存失败');
        }
        // }
      } catch (error) {
        console.log(error);
      }
    },

    //领域右上角驳回理由
    handleRealmReject(e, item) {
      e.stopPropagation();

      let realmResult = this.realmResults[Number(item.realmNo)];

      let { countyEvaluateRemark, countyEvaluateHandleStatus } = realmResult;
      if (Number(countyEvaluateHandleStatus) == 2 || Number(countyEvaluateHandleStatus) == 3) {
        //被拒绝

        this.operationLogs = this.realmOperationLogs[Number(item.realmNo)];
        this.visibleModalRemark = true;
      }
    },

    //展开所有折叠
    setCollapseActiveKey() {
      try {
        const _this = this;
        let collapseActiveKeyArray = [];
        _this.realmsList.forEach((item, index) => {
          collapseActiveKeyArray.push(index);
        });
        _this.collapseActiveKey = collapseActiveKeyArray;
      } catch (error) {
        console.log(error);
      }
    },

    //佐证附件上传
    async uploadFiles(info, item) {
      //初始化文件信息
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: 'uploading',
        response: '',
        url: ''
      };
      //放入上传列表中，以便于显示上传进度
      //开始真正上传
      //上传接口
      let uploadApiUrl = '/cmsapi/system/upload/tenant/upload/try-to-pdf';
      //调用公共上传方法
      this.tipText = '上传中...';
      this.spinning = true;
      const res = await this.uploadFilesToServer(uploadApiUrl, 'file', info.file);
      console.log(res);
      this.spinning = false;
      //根据服务端返回的结果判断成功与否，设置文件条目的状态
      if (res.code === '00000') {
        fileInfo.status = 'done';
        fileInfo.uid = res.data.id;
        fileInfo.response = res.data.message;
        fileInfo.url = res.data.url;
        this.$message.success('上传成功！');
        item.fileList.push(fileInfo);
      } else {
        fileInfo.status = 'error';
        fileInfo.response = res.data.message;
        // this.$message.error('上传失败！');
      }
    },
    /******************上传文件公共方法******************/
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      const _this = this;
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${_this.token}`
      };
      //配置头
      const request = axios.create({
        headers: headers
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    //上传前
    onBeforeUpload(file) {
      try {
        return new Promise((resolve, reject) => {
          const isLt50M = file.size / 1024 / 1024 < 100;
          if (!isLt50M) {
            message.warning('上传文件大小不能超过100MB！');
            return reject(false);
          }
          return resolve(true);
        });
      } catch (error) {
        console.error(error);
      }
    },
    //预览取消
    onCancelModalFile() {
      try {
        const _this = this;
        _this.visibleModalFile = false;
        _this.activeFile = {};
      } catch (error) {
        console.error(error);
      }
    },
    isImage(file) {
      const _this = this;
      let name = file.name;
      let splitList = name.split('.');
      let type = splitList[splitList.length - 1].toLowerCase();
      let imgType = ['gif', 'jpeg', 'jpg', 'png'];
      if (imgType.includes(type)) {
        // 图片，直接跳转打开
        return true;
      } else {
        return false;
      }
    },
    //预览
    async goPreview(file) {
      const _this = this;
      console.log(file);
      _this.tipText = '加载中...';
      _this.spinning = true;
      let name = file.name;
      let splitList = name.split('.');
      let type = splitList[splitList.length - 1].toLowerCase();

      let imgType = ['gif', 'jpeg', 'jpg', 'png'];
      let fileTypeList = ['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx'];
      let videoType = ['mp3', 'mp4'];
      if (fileTypeList.includes(type)) {
        console.log('文件');
        if (_this.previewFlag) {
          _this.spinning = false;
          return false;
        }
        _this.previewFlag = true;
        const { uid } = file;
        const res = await previewPdfApi({ uuid: uid });
        if (res && res.code === '00000') {
          let fileNameList = res.data.fileName.split('.');
          let fileType = fileNameList[fileNameList.length - 1].toLowerCase();

          _this.activeFile = {
            pdfSrc: res.data.url,
            fileType: fileType
          };
          _this.visibleModalFile = true;
        }

        setTimeout(() => {
          _this.previewFlag = false;
        }, 1000);
      } else if (type == 'pdf') {
        _this.activeFile = {
          pdfSrc: file.url
        };
        _this.visibleModalFile = true;
      } else if (imgType.includes(type)) {
        // 图片，直接跳转打开
        window.open(file.url);
      } else if (videoType.includes(type)) {
        _this.activeFileUrl = file.url;
        _this.activeFileType = type;
        _this.visibleModalMp = true;
      } else {
        message.error('预览暂不支持当前格式的文件');
      }
      _this.spinning = false;
    },
    //下载文件
    handleDownload(e) {
      this.tipText = '下载中...';
      this.spinning = true;
      downloadFile2(
        {
          url: e.url,
          fileName: e.name
        },
        () => {
          this.spinning = false;
        }
      );
    },
    //删除文件
    deleteFile(list, index) {
      list.splice(index, 1);
    },
    //减佐证
    deleteLine(list, index) {
      if (list.length <= 1) {
        return false;
      }
      list.splice(index, 1);
    },
    //增佐证
    addLine(list) {
      if (list.length >= this.fileLimit) {
        return false;
      }
      list.push({
        evidence: '',
        attachment: [],
        fileList: []
      });
    },
    //佐证材料默认增加一个
    selfEvaluateEvidencesEmptyAdd(list) {
      if (list.length <= 0 || !list) {
        list.push({
          evidence: '',
          attachment: [],
          fileList: []
        });
      }
      return list;
    },
    //设置最小和最大的 metricIdToKeyBySelf
    setMinAndMaxMetricIdToKeyBySelf() {
      try {
        const _this = this;
        _this.minMetricIdToKeyBySelf = _this.realmsList[0].metrics[0].metricIdToKeyBySelf;
        let lastRealm = _this.realmsList[_this.realmsList.length - 1];
        let lastMetric = lastRealm.metrics[lastRealm.metrics.length - 1];
        _this.maxMetricIdToKeyBySelf = lastMetric.metricIdToKeyBySelf;
      } catch (error) {
        console.log(error);
      }
    },
    //上一个指标
    preMetric() {
      const _this = this;
      if (_this.activeMetricIdToKeyBySelf == _this.minMetricIdToKeyBySelf) {
        return false;
      }
      _this.activeMetricIdToKeyBySelf--;

      _this.handleMetricAfter();
    },
    //下一个指标
    nextMetric() {
      const _this = this;
      if (_this.activeMetricIdToKeyBySelf == _this.maxMetricIdToKeyBySelf) {
        return false;
      }
      _this.activeMetricIdToKeyBySelf++;

      _this.handleMetricAfter();
    },
    //左侧metric点击改变激活的activeMetricIdToKeyBySelf
    changeMetric(e) {
      try {
        const _this = this;
        if (_this.activeMetricIdToKeyBySelf == e.metricIdToKeyBySelf) {
          return false;
        }
        _this.activeMetricIdToKeyBySelf = e.metricIdToKeyBySelf;

        _this.handleMetricAfter();
      } catch (error) {
        console.log(error);
      }
    },
    //字典评价方式转中文
    transformKeyToTextByDictionaries(key) {
      let text = '未知方式';
      this.evaluateType.forEach((item) => {
        if (key == item.value) {
          text = item.name;
        }
      });
      return text;
    },
    //转中文
    toChinesNum(num) {
      let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      let unit = ['', '十', '百', '千', '万'];
      num = parseInt(num);
      let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse();
        let newNum = '';
        let newArr = [];
        strArr.forEach((item, index) => {
          newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index]);
        });
        let numArr = [];
        newArr.forEach((m, n) => {
          if (m !== '零') numArr.push(n);
        });
        if (newArr.length > 1) {
          newArr.forEach((m, n) => {
            if (newArr[newArr.length - 1] === '零') {
              if (n <= numArr[numArr.length - 1]) {
                newNum += m;
              }
            } else {
              newNum += m;
            }
          });
        } else {
          newNum = newArr[0];
        }
        return newNum;
      };
      let overWan = Math.floor(num / 10000);
      let noWan = num % 10000;
      if (noWan.toString().length < 4) {
        noWan = '0' + noWan;
      }
      return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num);
    },
    //拼接id
    getFileIds(list) {
      let ids = [];
      list.forEach((item) => {
        if (item && item.id) {
          ids.push(item.id);
        } else if (item && item.uid) {
          ids.push(item.uid);
        }
      });
      return ids.join();
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.ant-collapse {
  border: none;
  background: transparent;
}
.ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.assessment-wrap .ant-upload-list {
  display: none;
}
.evaluate-item .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  background: #e5eeff;
  border-radius: 2px;
}
</style>
